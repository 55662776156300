import React, { useEffect } from "react";
import Swiper from "swiper";
import quitesImage from "../../images/card/quites.svg";
import star from "../../images/card/full-star.svg";

//import 'swiper/swiper-bundle.min.css';

const Testimonials = () => {
  useEffect(() => {
    new Swiper(".mySwiper", {
      slidesPerView: 1,
      spaceBetween: 10,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        640: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 25,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 40,
        },
      },
    });

    const handleScroll = () => {
      const header = document.getElementById("header");
      if (window.scrollY > 0) {
        header.classList.add("scrolled");
      } else {
        header.classList.remove("scrolled");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section className="sec-padd border-b">
      <div className="container">
        <h2 className="c-heading">Testimonials</h2>
        <div className="test-sec">
          <div className="swiper mySwiper sec-padd pt-5">
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <div className="test-div text-start">
                  <img src={quitesImage} alt="Illustration of quotes" />

                  {/* <h4>Quisque sempe</h4> */}
                  <p className="mb-4">
                  SpinSaga is my go-to social casino! I love the variety of games and the generous bonuses.
                  </p>
                  <img
                    src={star}
                    className="star_img"
                    alt="Illustration of quotes"
                  />
                  <h5>Sarah L</h5>
                  {/* <h6>Sales Manager</h6> */}
                </div>
              </div>
              <div className="swiper-slide">
                <div className="test-div text-start">
                  <img src={quitesImage} alt="Illustration of quotes" />
                  {/* <h4>Quisque sempe</h4> */}
                  <p className="mb-4">
                  The games are incredible! It feels like I'm right in the casino.
                  </p>
                  <img
                    src={star}
                    className="star_img"
                    alt="Illustration of quotes"
                  />
                  <h5>John M</h5>
                  {/* <h6>Sales Manager</h6> */}
                </div>
              </div>
              <div className="swiper-slide">
                <div className="test-div text-start">
                  <img src={quitesImage} alt="Illustration of quotes" />
                  {/* <h4>Quisque sempe</h4> */}
                  <p className="mb-4">
                  Great fun playing this game with family - SpinSaga is a winner!
                  </p>
                  <img
                    src={star}
                    className="star_img"
                    alt="Illustration of quotes"
                  />
                  <h5>Emily C</h5>
                  {/* <h6>Sales Manager</h6> */}
                </div>
              </div>
              <div className="swiper-slide">
                <div className="test-div text-start">
                  <img src={quitesImage} alt="Illustration of quotes" />
                  {/* <h4>Quisque sempe</h4> */}
                  <p className="mb-4">
                  So many games to choose from, and I keep hitting big wins while having fun!
                  </p>
                  <img
                    src={star}
                    className="star_img"
                    alt="Illustration of quotes"
                  />
                  <h5>David A</h5>
                  {/* <h6>Sales Manager</h6> */}
                </div>
                
              </div>
              <div className="swiper-slide">
                <div className="test-div text-start">
                  <img src={quitesImage} alt="Illustration of quotes" />
                  {/* <h4>Quisque sempe</h4> */}
                  <p className="mb-4">
                  SpinSaga is the most exciting online casino I've played at. Highly recommend!
                  </p>
                  <img
                    src={star}
                    className="star_img"
                    alt="Illustration of quotes"
                  />
                  <h5>Lisa B</h5>
                  {/* <h6>Sales Manager</h6> */}
                </div>
                
              </div>
             
            </div>
            <div className="swiper-pagination"></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
