import "./style.css";
import xIcon from "../../images/X.svg";
import instagramIcon from "../../images/Instagram.svg";
import facebookLogo from "../../images/Facebook.svg";
import discordLogo from "../../images/Discord.svg";
import logo from "../../images/logo.svg";
import skrill from "../../images/Skrill.svg";
import visa from "../../images/Visa.svg";
import mastercard from "../../images/Mastercard.svg";
import discovery from "../../images/Discovery.svg";
import arrow from "../../images/card/arrow.svg";
import Testimonials from "../Testimonials";
import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
const Home = () => {
  const [email, setEmail] = useState("");
  const [fullname, setFullname] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email || !fullname) {
      toast.error("Please fill out all fields.");
      return;
    }
    try {
      const response = await axios.post(
        "https://api-stage.spinsagacasino.com:5000/api/casino/casinosubscriber",
        {
          email: email,
          name: fullname,
        }
      );
      if (response.data.status == "success") {
        toast.success(response.data.message);
        setEmail("");
        setFullname("");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <ToastContainer position="bottom-center" limit={1} />
      <section className="sec-padd home-banner">
        <div className="d-md-none d-block">
          <div className="container">
            <div className="col-md-6 m-auto text-center">
              {/* <h2 className="mb-2">Tagline</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur <br />
                adipiscing elit. Mauris eget ante odio.
              </p> */}
              <div className="pt-5">
                <a
                  href="https://play.spinsagacasino.com/signup"
                  className="btn btn-gray"
                >
                  <p>JOIN NOW</p>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 m-auto text-end join_btn">
          <a
            href="https://play.spinsagacasino.com/signup"
            className="btn btn-blue"
          >
            JOIN NOW
          </a>
        </div>
      </section>

      <section className="sec-padd-t img-radius grab-sec d-md-block d-none">
        <div className="container">
          <h2 className="c-heading">
            Grab your Free Coins & <br /> Start Winning!
          </h2>
          <p className="sub-bold">
            Welcome to SpinSaga Social Casino, your one-stop shop for
            electrifying online entertainment!
            <br /> With a vast collection of slots, table games, and live dealer
            experiences, ensuring there's something for every type of player; we
            provide a safe, secure, and exhilarating gaming experience.
          </p>
          <div className="row g-md-5 g-4 pt-4">
            <div className="col-md-3 col-sm-6 col-6">
              <div className="card1">
                <a href="https://play.spinsagacasino.com/">
                  <img src={require("../../images/card/card1.webp")} alt="" />
                  <div className="card_name">
                    <p>Legacy of Dynasties</p>
                    <p>
                      <img src={arrow} alt="" />
                    </p>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-6">
              <div className="card1">
                <a href="https://play.spinsagacasino.com/">
                  <img src={require("../../images/card/card2.webp")} alt="" />
                  <div className="card_name">
                    <p>Ruff Heist</p>
                    <p>
                      <img src={arrow} alt="" />
                    </p>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-6">
              <div className="card1">
                <a href="https://play.spinsagacasino.com/">
                  <img src={require("../../images/card/card3.webp")} alt="" />
                  <div className="card_name">
                    <p>Piggy Blitz</p>
                    <p>
                      <img src={arrow} alt="" />
                    </p>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-6">
              <div className="card1">
                <a href="https://play.spinsagacasino.com/">
                  <img src={require("../../images/card/card4.webp")} alt="" />
                  <div className="card_name">
                    <p>Sherwood Gold</p>
                    <p>
                      <img src={arrow} alt="" />
                    </p>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-6">
              <div className="card1">
                <a href="https://play.spinsagacasino.com/">
                  <img src={require("../../images/card/card5.webp")} alt="" />
                  <div className="card_name">
                    <p>Monkey</p>
                    <p>
                      <img src={arrow} alt="" />
                    </p>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-6">
              <div className="card1">
                <a href="https://play.spinsagacasino.com/">
                  <img src={require("../../images/card/card6.webp")} alt="" />
                  <div className="card_name">
                    <p>Green Knight</p>
                    <p>
                      <img src={arrow} alt="" />
                    </p>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-6">
              <div className="card1">
                <a href="https://play.spinsagacasino.com/">
                  <img src={require("../../images/card/card07.webp")} alt="" />
                  <div className="card_name">
                    <p>Fox Mayhem</p>
                    <p>
                      <img src={arrow} alt="" />
                    </p>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-6">
              <div className="card1">
                <a href="https://play.spinsagacasino.com/">
                  <img src={require("../../images/card/card8.webp")} alt="" />
                  <div className="card_name">
                    <p>Sparky & Shortz</p>
                    <p>
                      <img src={arrow} alt="" />
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padd-t img-radius grab-sec d-block d-md-none">
        <div className="container">
          <h2 className="c-heading">
            Grab your Free Coins & <br /> Start Winning!
          </h2>
          <p className="sub-bold">
            Welcome to SpinSaga Social Casino, your one-stop shop for
            electrifying online entertainment!
            <br /> With a vast collection of slots, table games, and live dealer
            experiences, ensuring there's something for every type of player; we
            provide a safe, secure, and exhilarating gaming experience.
          </p>

          <div className="row g-md-5 g-4 pt-4">
            <div className="col-md-12">
              <p className="sub-bold mb-3 text-start">Featured Games</p>
            </div>
            <div className="col-4">
              <div className="card1">
                <a href="https://play.spinsagacasino.com/">
                  <img src={require("../../images/card/card1.webp")} alt="" />
                </a>
              </div>
            </div>
            <div className=" col-4">
              <div className="card1">
                <a href="https://play.spinsagacasino.com/">
                  <img src={require("../../images/card/card2.webp")} alt="" />
                </a>
              </div>
            </div>
            <div className="col-4">
              <div className="card1">
                <a href="https://play.spinsagacasino.com/">
                  <img src={require("../../images/card/card3.webp")} alt="" />
                </a>
              </div>
            </div>
          </div>

          <div className="row g-md-5 g-4 pt-4">
            <div className="col-md-12">
              <p className="sub-bold mb-3 text-start">New Games</p>
            </div>
            <div className="col-4">
              <div className="card1">
                <a href="https://play.spinsagacasino.com/">
                  <img src={require("../../images/card/card5.webp")} alt="" />
                </a>
              </div>
            </div>
            <div className=" col-4">
              <div className="card1">
                <a href="https://play.spinsagacasino.com/">
                  <img src={require("../../images/card/card6.webp")} alt="" />
                </a>
              </div>
            </div>
            <div className="col-4">
              <div className="card1">
                <a href="https://play.spinsagacasino.com/">
                  <img src={require("../../images/card/card7.webp")} alt="" />
                </a>
              </div>
            </div>
          </div>

          <div className="row g-md-5 g-4 pt-4">
            <div className="col-md-12">
              <p className="sub-bold mb-3 text-start">Popular Games</p>
            </div>
            <div className="col-4">
              <div className="card1">
                <a href="https://play.spinsagacasino.com/">
                  <img src={require("../../images/card/card9.webp")} alt="" />
                </a>
              </div>
            </div>
            <div className=" col-4">
              <div className="card1">
                <a href="https://play.spinsagacasino.com/">
                  <img src={require("../../images/card/card10.webp")} alt="" />
                </a>
              </div>
            </div>
            <div className="col-4">
              <div className="card1">
                <a href="https://play.spinsagacasino.com/">
                  <img src={require("../../images/card/card11.webp")} alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padd img-radius border_b d-md-block d-none">
        <div className="container">
          <div className="row g-md-5 g-4 pt-4">
            <div className="col-md-3 col-sm-6 col-6">
              <div className="card1">
                <a href="https://play.spinsagacasino.com/">
                  <img src={require("../../images/card/card9.webp")} alt="" />
                  <div className="card_name">
                    <p>Free Reelin Joker</p>
                    <p>
                      <img src={arrow} alt="" />
                    </p>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-6">
              <div className="card1">
                <a href="https://play.spinsagacasino.com/">
                  <img src={require("../../images/card/card10.webp")} alt="" />
                  <div className="card_name">
                    <p>Slashimi</p>
                    <p>
                      <img src={arrow} alt="" />
                    </p>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-6">
              <div className="card1">
                <a href="https://play.spinsagacasino.com/">
                  <img src={require("../../images/card/card11.webp")} alt="" />
                  <div className="card_name">
                    <p>Wild Bandolier</p>
                    <p>
                      <img src={arrow} alt="" />
                    </p>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-6">
              <div className="card1">
                <a href="https://play.spinsagacasino.com/">
                  <img src={require("../../images/card/card12.webp")} alt="" />
                  <div className="card_name">
                    <p>Leprechauns Vault</p>
                    <p>
                      <img src={arrow} alt="" />
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padd border-b no-cardp">
        <div className="container">
          <h2>Reasons to Join SpinSaga Today</h2>
          <p>
            <strong>Spin the Wheel of Fortune:</strong> Join SpinSaga and start
            with a bang! We welcome new players with a free coins bonus, letting
            you try your luck on our exciting games without spending a penny.
          </p>
          <p>
            <strong>Endless Gaming Variety:</strong> Dive into a world of
            possibilities! Explore our extensive slots library, classic table
            games, and immersive gaming experiences that put you right in the
            heart of the action.
          </p>
          {/* <p>
          <strong>Exciting Rewards, Big Wins:</strong> Play with coins and unlock the chance to 
          redeem gift cards from top luxury brands! SpinSaga offers easy, secure 
          banking for smooth deposits, plus ongoing promotions 
          and loyalty rewards to keep the fun going.
          </p> */}
          <p>
            <strong>Unparalleled Customer Support:</strong> Our friendly and
            knowledgeable customer support team is here for you, 24/7. We're
            committed to providing a seamless and enjoyable gaming experience
            for all our players.
          </p>
          <p>
            <strong>Safety & Security Guaranteed:</strong> At SpinSaga, your
            safety and security are our top priorities. We utilize
            state-of-the-art encryption technology and adhere to the strictest
            industry standards to protect your information.
          </p>
          {/* <h2 className="c-heading">
            Reasons to Join SPIN SAGA today
           
          </h2>

          <p className="sub-bold text-center">
            Spin the Wheel of Fortune: Join Spinsaga and start with a bang! We
            welcome new players with a free coins bonus, letting you try your
            luck on our exciting games without spending a penny.
          </p>
          <p className="sub-bold text-center">
            Endless Gaming Variety: Dive into a world of possibilities! Explore
            our extensive slots library, classic table games, and immersive
            gaming experiences that put you right in the heart of the action.
          </p>
          <p className="sub-bold text-center">
            Real Money Wins, Real Rewards: Play for real money and experience
            the thrill of a big win! Spinsaga offers secure and convenient
            banking options for smooth deposits and withdrawals. Plus, enjoy
            ongoing promotions and loyalty rewards that keep the excitement
            going.
          </p>
          <p className="sub-bold text-center">
            Unparalleled Customer Support: Our friendly and knowledgeable
            customer support team is here for you, 24/7. We're committed to
            providing a seamless and enjoyable gaming experience for all our
            players.
          </p>
          <p className="sub-bold text-center">
            Safety & Security Guaranteed: At Spinsaga Casino, your safety and
            security are our top priorities. We utilize state-of-the-art
            encryption technology and adhere to the strictest industry standards
            to protect your information and transactions.
          </p> */}
          {/* <div className="row g-md-5 g-4 pt-5">
            <div className="col-md-4">
              <div className="card1">
                <img src={require("../../images/new-images/ClaimDeposit.jpg")} alt="" />
                <div className="card_name">
                  <p>
                    Claim your 100% first deposit bonus today! Double your
                    starting balance and spin your way to big wins
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card1">
                <img src={require("../../images/new-images/SundayBonus.jpg")} alt="" />
                <div className="card_name">
                  <p>
                    Sunday Bonus Here’s a weekly treat and boost your coins
                    every Sunday. Claim Free 50 SpinSaga Coins!
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card1">
                <img src={require("../../images/new-images/ten.jpg")} alt="" />
                <div className="card_name">
                  <p>
                    10% Weekly Cashback Get Rewarded for Every Spin! Earn cash
                    back every time you spin at SpinSaga Casino!
                  </p>
                </div>
              </div>
            </div>
          </div> */}
          <div className="row g-md-5 g-4 pt-5">
            <div className="col-md-4">
              <div className="card1">
                <img
                  src={require("../../images/new-images/spinlogin.jpg")}
                  alt=""
                />
                <div className="card_name">
                  <p>
                    Daily login, daily rewards! Earn 2 Saga Coins every day just
                    for showing up and staying in the game!
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card1">
                <img src={require("../../images/new-images/VIP.jpg")} alt="" />
                <div className="card_name">
                  <p>
                    Be part of the Elite VIP Club when you join SpinSaga. Enjoy
                    personalized promotions and exclusive bonuses!
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card1">
                <img
                  src={require("../../images/new-images/ReferaFreind.jpg")}
                  alt=""
                />
                <div className="card_name">
                  <p>
                    Refer your friends to SpinSaga and earn 10 Saga Coins each
                    time they join the fun – it’s a win-win!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Testimonials />

      <section className="sec-padd top-sec">
        <div className="container">
          <div className="row g-4 align-items-center">
            <div className="col-md-5 text-center">
              <h2 className="c-heading pb-4">Top Game of the Week</h2>
              <h4 className="sub-bold f-400 mb-4">Claim your Free Coins</h4>
              <p className="f-300">
                Our thriving social community is focused entirely on having fun,
                so why not join the SpinSaga party and start winning today?
              </p>
              <div className="pt-5">
                <a
                  href="https://play.spinsagacasino.com/signup"
                  className="btn btn-red"
                >
                  JOIN NOW
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="sec-padd border-b no-cardp card_u">
        <div className="container">
          <div className="row g-md-5 g-4 pt-5">
            <div className="col-md-4">
              <div className="card1">
                <img src={require("../../images/new-images/First.jpg")} alt="" />
                <div className="card_name">
                  <p>
                    Double your excitement, double your wins! Your money,
                    doubled! Claim your 100% deposit match at SpinSaga Casino.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card1">
                <img src={require("../../images/new-images/VIP.jpg")} alt="" />
                <div className="card_name">
                  <p>
                    Be part of the Elite VIP Club when you join SpinSaga
                    Casino. Enjoy personalized promotions, exclusive bonuses,
                    and faster withdrawals.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card1">
                <img src={require("../../images/new-images/ReferaFreind.jpg")} alt="" />
                <div className="card_name">
                  <p>
                    Share the fun and earn rewards! Refer a friend to SpinSaga
                    Casino and earn a generous bonus. Invite your friends and
                    win together!
                  </p>
                </div>
              </div>
            </div>
            <div className="pt-5 text-center d-md-block d-none">
              <a
                href="https://play.spinsagacasino.com/"
                className="btn btn-green"
              >
                PLAY FOR FREE
              </a>
            </div>
          </div>
        </div>
      </section> */}

      <section className="sec-padd border-b form-sec">
        <div className="container">
          <h2 className="c-heading">Get In Touch</h2>
          <p className="text-center pt-4">
            Have a question? Contact our support team today.
          </p>
          <p className="text-center pt-4">
            They are available to help you out 24/7 in case of any queries or
            technical issues.
          </p>
          <div className="row g-4 pt-5">
            <form onSubmit={handleSubmit}>
              <div className="col-md-8 m-auto">
                <div className="row g-4">
                  <div className="col-md-4">
                    <input
                      className="w-100"
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Email Address"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-md-4">
                    <input
                      className="w-100"
                      type="text"
                      name="fullName"
                      id="fullName"
                      placeholder="Full Name"
                      value={fullname}
                      onChange={(e) => {
                        setFullname(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-md-4">
                    <input type="submit" value="Subscribe" />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
      {/* <footer>
        <div className="container">
          <div className="row g-4">
            <div className="col-md-4 text-center text-md-start">
              <a href="#" className="footer-logo">
                <img className="footer-logo" src={logo} alt="logo1" />
              </a>
              <div className="d-md-none d-block">
                <ul className="flex-social justify-content-center">
                  <li>
                    <a href="#">
                      <img src={xIcon} alt="X" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={instagramIcon} alt="Instagram" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={facebookLogo} alt="Facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={discordLogo} alt="Discord" />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="flex-logo footer-logo-space d-md-flex d-none">
                <a href="#">
                  <img src={skrill} alt="Skrill" />
                </a>
                <a href="#">
                  <img src={visa} alt="Visa" />
                </a>
                <a href="#">
                  <img src={mastercard} alt="Mastercard" />
                </a>
                <a href="#">
                  <img src={discovery} alt="Discovery" />
                </a>
              </div>
            </div>
            <div className="col-md-2 col-6">
              <h4>SUPPORT</h4>
              <ul>
                <li>
                  <a href="/contact-us">Contact Us</a>
                </li>
                <li>
                  <a href="/responsible-gaming">Responsible Play</a>
                </li>
                <li>
                  <a href="/faq">FAQs</a>
                </li>
              </ul>
            </div>
            <div className="col-md-4 col-6">
              <h4>PLAYING AT SPINWAY</h4>
              <ul>
                <li>
                  <a href="/about-us">About Us</a>
                </li>
                <li>
                  <a href="/terms-conditions">Terms and Conditions</a>
                </li>
                <li>
                  <a href="/privacy-policy">Privacy Policy</a>
                </li>
                <li>
                  <a href="/sweepstakes-rules">Sweepstakes Rules</a>
                </li>
              </ul>
            </div>
            <div className="col-md-2">
              <div className="d-none d-md-block">
                <ul className="flex-social">
                  <li>
                    <a href="https://x.com/SpinSagaCasino" target="a_blank">
                      <img src={xIcon} alt="X" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/spinsagacasino/" target="a_blank">
                      <img src={instagramIcon} alt="Instagram" />
                    </a>
                  </li>
                </ul>
                <ul className="flex-social">
                  <li>
                    <a href="https://www.facebook.com/profile.php?id=61563257325711" target="a_blank">
                      <img src={facebookLogo} alt="Facebook" />
                    </a>
                  </li>
                 
                </ul>
              </div>
            </div>
          </div>

          <div className="copyright text-center">
            <div className="flex-logo footer-logo-space d-md-none">
              <a href="#">
                <img src={skrill} alt="Skrill" />
              </a>
              <a href="#">
                <img src={visa} alt="Visa" />
              </a>
              <a href="#">
                <img src={mastercard} alt="Mastercard" />
              </a>
              <a href="#">
                <img src={discovery} alt="Discovery" />
              </a>
            </div>
            <p>© Spin Saga Casino All Rights Reserved</p>
          </div>
        </div>
      </footer> */}
    </div>
  );
};
export default Home;
