// src/App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

import AboutUs from "./AboutUs";
import Home from "./Home";

import Sweepstakes from "./SweepStakes";
import TermAndCondition from "./TermAndCondition";
import Privacy from "./Privacy";
import ResponsibleGaming from "./ResponsibleGaming";
import ComingSoon from './ComingSoon'
function RouteList() {
  return (
    <div>
      {/* <Router> */}
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/about" element={<AboutUs />} /> */}
        {/* <Route path="/privacy" element={<Privacy />} /> */}
        {/* <Route path="/responsible-gaming" element={<ResponsibleGaming />} /> */}
        {/* <Route path="/sweep-stakes" element={<Sweepstakes />} /> */}
        {/* <Route path="/termCondition" element={<TermAndCondition />} /> */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      {/* </Router> */}
    </div>
  );
}

export default RouteList;
